
export default {
	name: 'organizationList',
	data() {
		return {
			list: '',
			search: '',
			total: 0,
			pageSize: 0,
			currentPage: 1,
			searchers: false,
		}
	},

	created() {
		this.token = this.getCookie('token')
		this.getOfficeList()
	},
	methods: {
		getOfficeList() {
			var _this = this;
			this.searchers = false;
			_this.$http.post('/API/PLAT/smoPageA', {
				name: "",
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage;
			if (this.searchers) {
				this.searchBtn()
			} else {
				this.getOfficeList()
			}
		},
		searchBtn() {
			var _this = this;
			this.searchers = true;
			_this.$http.post('/API/PLAT/smoPageA', {
				name: _this.search,
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		toPar(id) {
			this.$router.push({ name: 'crcParticulars', params: { id: id } });
		},
		toCrcMena() {
			this.$router.push('/smoStatistics')
		},
		export2Excel() {
			require.ensure([], () => {
				const { export_json_to_excel } = require('../../vendor/Export2Excel');
				const tHeader = ['机构名称', '科室', 'CRC', '年龄', '学历', '专业', '从业年限', '项目数量'];
				const filterVal = ['inName', 'departmentName', 'crcName', 'years', 'education', 'major', 'age', 'psize'];
				const list = this.list;
				const data = this.formatJson(filterVal, list);
				export_json_to_excel(tHeader, data, '机构人员详情列表');
			})
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
